import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import SEO from "../components/SEO"

const Error = () => {
  return (
    <Layout>
      <SEO title="404 - Missing page" description="404 error for missing page / incorrect URL"/>
      <main className="error-page">
        <div className="error-container">
          <h1>Missing Page</h1>
          <h4>Sorry, that page doesn't exist</h4>
          <Link to='/' className="btn">Back to home</Link>
        </div>
      </main>
    </Layout>
    
  )
}

export default Error
